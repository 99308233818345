import React from 'react';
import VideoAndTextComponetsLibrary from '@mshops-components-library/video-and-text';

import { useRenderContext } from '../../pages/home/context';

const VideoAndText = (props) => {
  const { device, isEshops, theme, lowEnd } = useRenderContext();

  return (
    <VideoAndTextComponetsLibrary
      layoutTheme={theme}
      deviceType={device}
      lowEnd={lowEnd}
      isEshops={isEshops}
      {...props}
    />
  );
};

export default VideoAndText;
